import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        user: {}, 
        auth: {}
    },

    mutations: {
        SET_USER(state, payload) {
            state.user = payload;
            if(payload.token) {
                localStorage.setItem('pvzcontrol-admin-token', payload.token);
            }
        },
        CLEAR_USER(state) {
            state.user = {};
            localStorage.removeItem('pvzcontrol-admin-token');
        },
        SET_VALUE(state, { prop, value }) {
            state.user[prop] = value;
        },
        SET_AUTH(state, payload) {
            state.auth = payload;
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/user/profile');
            if(data.id) {
                commit('SET_USER', data);
            } else {
                commit('CLEAR_USER');
            }
            return data;
        },
        async save({ commit, state }) {
            let response = {};
            if(state.user.id) {
                response = await axios.put('/user/profile', state.user);
            }
        },
        async login({ commit }, { email, password }) {
            const { data } = await axios.post('/user/login', { email, password });
            console.log(data);
            commit('SET_USER', data);
            return data;
        },
        async confirm({ state, commit }, { code }) {
            const { data } = await axios.post('/user/confirm', { ...state.auth, ...{ code } });
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            return data;
        },
        async logout({ commit }) {
            commit('CLEAR_USER');
        }
    },

    getters: {}
}