import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import regions from './modules/regions';
import pvzs from './modules/pvzs';
import cameras from './modules/cameras';
import ratings from './modules/ratings';

import inventories from './modules/inventories';
import checkouts from './modules/checkouts';
import documents from './modules/documents';

import partners from './modules/partners';
import users from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        regions,
        pvzs,
        cameras,
        ratings,
        inventories,
        checkouts,
        documents,
        partners,
        users
    }
})
