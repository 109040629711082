import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        chartEntities: []
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload.results;
            state.entitiesCount = payload.total;
        },
        SET_CHART_ENTITIES(state, payload) {
            state.chartEntities = payload;
        },
    },

    actions: {
        async fetch({ commit }, { page = 1, itemsPerPage = 100, filter }) {
            const { data } = await axios.get('/rating', { params: { page, itemsPerPage, ...filter } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async summary({ commit }, { filter }) {
            const { data } = await axios.get('/rating/summary', { params: { ...filter } });
            return data;
        },
        async export({ commit }, { filter }) {
            const { data } = await axios.get('/rating/export', { params: { ...filter } });
            return data;
        },
        async chart({ commit }, { filter }) {
            const { data } = await axios.get('/rating/chart', { params: filter });
            commit('SET_CHART_ENTITIES', data);
            return data;
        }
    },

    getters: {}
}
