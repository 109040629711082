import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload.results;
            state.entitiesCount = payload.total;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async fetch({ commit }, { page = 1, itemsPerPage = 100, filter }) {
            const { data } = await axios.get('/inventory', { params: { page, itemsPerPage, ...filter } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async summary({ commit }, { filter }) {
            const { data } = await axios.get('/inventory/summary', { params: { ...filter } });
            return data;
        },
        async export({ commit }, { filter }) {
            const { data } = await axios.get('/inventory/export', { params: { ...filter } });
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/inventory/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity.id) {
                response = await axios.put('/inventory', state.entity);
            } else {
                response = await axios.post('/inventory', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/inventory/${ id }`);
        },
    },

    getters: {}
}
