import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'dashboard'
        }
    }, {
        path: '/user/authorization',
        name: 'user-authorization',
        component: () => import('../views/user/Authorization.vue')
    }, {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue')
    }, {
        path: '/regions',
        name: 'regions',
        component: () => import('../views/regions/List.vue')
    }, {
        path: '/pvzs',
        name: 'pvzs',
        component: () => import('../views/pvzs/List.vue')
    }, {
        path: '/cameras',
        name: 'cameras',
        component: () => import('../views/cameras/List.vue')
    }, {
        path: '/ratings',
        name: 'ratings',
        component: () => import('../views/ratings/List.vue')
    }, {
        path: '/checkouts',
        name: 'checkouts',
        component: () => import('../views/checkouts/List.vue')
    }, {
        path: '/inventories',
        name: 'inventories',
        component: () => import('../views/inventories/List.vue')
    }, {
        path: '/documents',
        name: 'documents',
        component: () => import('../views/documents/List.vue')
    }, {
        path: '/users',
        name: 'users',
        component: () => import('../views/users/List.vue')
    }, {
        path: '/partners',
        name: 'partners',
        component: () => import('../views/partners/List.vue')
    },
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
