<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
            absolute
            elevate-on-scroll
        >
            <v-app-bar-nav-icon 
                v-if="user.id"
                @click.stop="drawer = !drawer" />
        </v-app-bar>

        <v-main>
            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
            >
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group>
                        <v-list-item @click="$router.push({ name: 'dashboard' })">
                            <v-list-item-title>Главная</v-list-item-title>
                        </v-list-item>
                        <template v-if="user.role === 'admin'">
                            <v-divider />
                            <v-list-item @click="$router.push({ name: 'partners' })">
                                <v-list-item-title>Партнеры</v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-if="user.role === 'admin' || user.role === 'partner'">
                            <v-divider />
                            <v-list-item @click="$router.push({ name: 'users' })">
                                <v-list-item-title>Пользователи</v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-if="user.role === 'admin' || user.role === 'partner'">
                            <v-divider />
                            <v-list-item @click="$router.push({ name: 'regions' })">
                                <v-list-item-title>Регионы</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$router.push({ name: 'pvzs' })">
                                <v-list-item-title>ПВЗ</v-list-item-title>
                            </v-list-item>
                        </template>
                        <v-divider />
                        <template v-if="user.role === 'admin' || user.role === 'partner'">
                            <v-list-item @click="$router.push({ name: 'cameras' })">
                                <v-list-item-title>Камеры</v-list-item-title>
                            </v-list-item>
                        </template>
                        <v-list-item @click="$router.push({ name: 'ratings' })">
                            <v-list-item-title>Рейтинг</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="$router.push({ name: 'checkouts' })">
                            <v-list-item-title>Сверка кассы</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$router.push({ name: 'inventories' })">
                            <v-list-item-title>Инвентаризации</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$router.push({ name: 'documents' })">
                            <v-list-item-title>Документы</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="logout">
                            <v-list-item-title>Выйти</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <div class="ma-4">
                <router-view />
            </div>
        </v-main>
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'PVZControl',
        metaInfo: {
            title: 'PVZControl',
            titleTemplate: '%s | TIDwear'
        },
        data: () => ({
            drawer: null,
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        async mounted() {
            if(!this.user.id) {
                await store.dispatch('auth/fetch');
                if (!this.user.id) {
                    await this.$router.push({ name: 'user-authorization' });
                }
            }
        },
        methods: {
            async logout() {
                await store.dispatch('auth/logout');
                await this.$router.push({ name: 'user-authorization' });
            }
        }
    };
</script>